import { fabric } from 'fabric'
import editorInit from './fabric/main.js'
import wx from 'weixin-js-sdk'
import { Dialog, Toast } from 'vant'
import { createUniqueString } from '@/utils/util'
import { uploadFile, FileUrl } from '@/api/base'

// 引入组件 start
import DefaultPanel from './components/default-panel'
import TextPanel from './components/text-panel'
import ImgPanel from './components/img-panel'
import ImgCutPanel from './components/img-cut-panel'
import IconPanel from './components/icon-panel'
import IconStylePanel from './components/icon-style-panel'
import StylesPanel from './components/styles-panel'
import TextColorsPanel from './components/text-colors-panel'
import LayerLevelPanel from './components/layer-level-panel'
import TransparentPanel from './components/transparent-panel'
import LuminancePanel from './components/luminance-panel'
import TextareaModal from './components/textarea-modal'

// 数据
import { postOperationConfig, getGoodDetail, getGoodJson, listColor, listFonts, listSvg } from '@/api/base'
// 引入组件 end

export default {
  components: {
    DefaultPanel,
    TextPanel,
    ImgPanel,
    ImgCutPanel,
    IconPanel,
    IconStylePanel,
    StylesPanel,
    TextColorsPanel,
    LayerLevelPanel,
    TransparentPanel,
    LuminancePanel,
    TextareaModal,
  },
  data() {
    return {
      maxScale: 2.0,
      id: null, // 商品id
      orderId: null, //订单id
      temp: null,
      loading: true,
      showLoading: false,
      showPng: false,
      imagesPreview: [],
      showTip: false,
      tipsTop: true, // 顶部提示 开关
      tipsTopConfig: '', // 提示语
      tipsBottom: false, // 底部提示 开关
      tipsBottomConfig: '', //提示语
      preview: true, // 图片预览
      fileUrl: FileUrl,
      title: '在线定制',
      designJson: {},
      psdZoom: 1,
      obj: {},
      layers: [],
      bgImage: '',
      colorList: [], // 字体颜色列表
      fontFamilyList: [], // 字体列表
      svgList: [], // 素材列表
      modelWidth: 0,
      modelHeight: 0,
      editorCanvas: {},
      workspace: {
        translateY: 0,
      },
      unRedoStatus: {
        undo: false,
        redo: false,
      }, // 撤销/回退的可操作状态
      lastPanelModel: '', // 上次打开的面板
      curPanelModel: 'show-default-panel', // 当前面板显示控制
      fontMaps: {},
    }
  },
  watch: {
    'editorCanvas._config': {
      deep: true,
      // immediate: true,
      handler(newVal, oldVal) {
        this.unRedoStatus.redo = newVal.redoBtnStatus
        this.unRedoStatus.undo = newVal.undoBtnStatus
      },
    },
  },
  /* 在vue的create或是mounted生命周期函数中获取从小程序传递过来的数据 */
  async mounted() {
    // H5页面入口处添加如下配置即可
    const metaNode = document.createElement('meta')
    metaNode.name = 'viewport'
    metaNode.content = 'width=device-width, initial-scale=1.0,minimum-scale=1.0, maximum-scale=1.0, user-scalable=no'
    document.head.appendChild(metaNode)

    this.getConfig()

    const url = new URL(window.location.href)
    this.id = url.searchParams.get('id') || 40
    this.orderId = url.searchParams.get('orderId')
    if (this.orderId) {
      await this.getTemplateJson(this.orderId)
    } else {
      await this.getPsdLayers(this.id)
    }
    await this.getFonts()
    this.getColors()
    this.getSvg()
    this.init()
  },
  methods: {
    // 获取引导配置信息
    async getConfig() {
      try {
        const config_left = {
          configGroup: 'call_Word_config',
          configKey: 'controlsUpperLeft',
        }
        const {
          data: { configValue: leftConfig },
        } = await postOperationConfig(config_left)
        this.tipsTopConfig = leftConfig
        const config_right = {
          configGroup: 'call_Word_config',
          configKey: 'controlsLowerRight',
        }
        const {
          data: { configValue: rightConfig },
        } = await postOperationConfig(config_right)
        this.tipsBottomConfig = rightConfig
        this.showTip = true
      } catch (error) {
        console.error('获取引导配置信息出错', error)
      }
    },

    /**
     * 步骤按钮 1
     */
    handleOk() {
      this.tipsTop = false
      this.tipsBottom = true
    },
    /**
     * 步骤按钮 2
     */
    handleBottom() {
      this.tipsTop = false
      this.tipsBottom = false
      this.showTip = false
    },
    /** 页面重新刷新 */
    reload() {
      this.init()
    },

    /** 完成 */
    async finish() {
      const _this = this
      const png = _this.editorCanvas.toPng()

      this.imagesPreview = [png]
      this.showPng = true
    },

    /** 确认下单 */
    async handleConfirm(png) {
      const _this = this
      _this.showPng = false
      const json = _this.editorCanvas.toJSon()
      if (json.objects.length > 0) {
        json.objects.forEach(async item => {
          // 判断type是图片 并且src是base64格式
          if (item.type === 'image' && item.src.indexOf('base64') > -1) {
            const suffix = _this.getExtensionFromBase64(item.src)
            const file = _this.dataURLtoFile(item.src, `${createUniqueString()}.${suffix}`)
            const fileData = new FormData()
            fileData.append('file', file)
            fileData.append('uploadType', 0)
            // 上传
            const {
              data: { filePath },
            } = await uploadFile(fileData)
            item.src = this.fileUrl + filePath
          }
        })
      }
      Dialog.confirm({
        title: '确认下单',
        message: '确认已设计完成，是否下单?',
        width: 300,
        confirmButtonText: '下单',
      })
        .then(async () => {
          _this.showLoading = true
          try {
            // 上传预览图
            const suffix = _this.getExtensionFromBase64(png)
            const file = _this.dataURLtoFile(png, `${createUniqueString()}.${suffix}`)
            const fileData = new FormData()
            fileData.append('file', file)
            fileData.append('uploadType', 0)
            // 上传
            const {
              data: { filePath },
            } = await uploadFile(fileData)
            const formData = {
              id: _this.id,
              designJson: JSON.stringify(json),
              makeFile: filePath,
            }
            wx.miniProgram.postMessage({
              data: JSON.stringify(formData),
            })
            _this.showLoading = false
            wx.miniProgram.redirectTo({
              url: '/pages/order/create/create?formData=' + encodeURIComponent(JSON.stringify(formData)), // 小程序中页面的路径
            })
          } catch (error) {
            _this.showLoading = false
          }
        })
        .catch(() => {
          // on cancel
        })
    },
    /** 初始化 */
    init() {
      this.layers.forEach((x, i) => {
        x.order = i
      })
      const workspace = this.$refs['ps-editor-workspace']
      const mainContent = this.$refs['editor-canvas-main']
      const hRadio = this.modelHeight / workspace.offsetHeight

      this.psdZoom = 1 / hRadio
      mainContent.style.width = `${this.modelWidth}px`
      mainContent.style.height = `${this.modelHeight}px`
      mainContent.style.transform = `scale(${this.psdZoom})`
      mainContent.style.transformOrigin = `0 0`
      this.start()
    },

    /** 开始初始化编译器 */
    async start() {
      const _this = this
      _this.editorCanvas = await editorInit('editor-canvas', fabric, _this)
      if (_this.orderId) {
        _this.editorCanvas.fabricInstance.clear()
        _this.editorCanvas.fabricInstance.loadFromJSON(_this.designJson, function () {
          _this.editorCanvas.fabricInstance.requestRenderAll() // 请求重新渲染画布
          setTimeout(() => {
            _this.getNewObjectList()
          }, 100)
        })
      } else {
        await _this.editorCanvas.addObjects(_this.layers)
        await _this.editorCanvas.initCanvas()
      }
    },

    // 点击画布之外重置操作栏等
    resetEditorTools() {
      this.curPanelModel = 'show-default-panel'
      this.editorCanvas.fabricInstance.discardActiveObject()
    },

    // 根据订单id 获取json数据
    async getTemplateJson(orderId) {
      try {
        const { data } = await getGoodJson(orderId)
        this.title = data.commodityName
        this.designJson = JSON.parse(data.designJson)
        this.modelWidth = this.designJson.backgroundImage.width
        this.modelHeight = this.designJson.backgroundImage.height
        this.loading = false
      } catch (error) {
        Toast('获取商品模板失败')
      }
    },
    // 根据商品id获取psd图层
    async getPsdLayers(id) {
      try {
        const { data } = await getGoodDetail(id)
        this.title = data.commodityName
        this.obj = JSON.parse(data.templateJson)
        this.bgImage = this.obj.background.image
        this.layers = this.obj.clouds.map(item => {
          if (item.type === 'text') {
            item.text = item.text.replace(/\r/g, '\n')
          }
          return item
        })
        this.modelWidth = this.obj.width
        this.modelHeight = this.obj.height

        this.loading = false
      } catch (error) {
        Toast('获取商品模板失败')
      }
    },

    // 获取字体
    async getFonts() {
      try {
        const { data } = await listFonts()
        let rule = ''
        const list = data.map(item => {
          // 字体文件
          item.fontFile = item.fontFile ? this.fileUrl + item.fontFile : null
          // 字体图标
          item.fontImageFile = item.fontImageFile ? this.fileUrl + item.fontImageFile : null
          rule += '@font-face {font-family:"' + item.fontName + '";src:url("' + item.fontFile + '");}'
          return {
            psName: item.fontName,
            name: item.psName,
            loading: false,
            preview: item.fontImageFile,
            download: item.fontFile,
          }
        })
        // 创建style 标签 写入字体信息
        const sty = document.createElement('style')
        sty.type = 'text/css'
        sty.innerHTML = rule
        document.getElementsByTagName('head')[0].appendChild(sty)
        this.fontFamilyList = list
      } catch (error) {
        this.fontFamilyList = []
        Toast('获取字体数据失败')
      }
    },

    // 获取字体颜色
    async getColors() {
      try {
        const { data } = await listColor()
        this.colorList = data
      } catch (error) {
        this.colorList = []
        Toast('获取字体颜色数据失败')
      }
    },

    //获取素材数据
    async getSvg() {
      try {
        const { data } = await listSvg()
        this.svgList = data.map(item => {
          item.iconFile = item.iconFile ? this.fileUrl + item.iconFile : null
          return item
        })
      } catch (error) {
        this.svgList = []
        Toast('获取素材数据失败')
      }
    },

    // 设置手势
    setITouch(e) {
      this.temp = e
    },
    // 切换编辑面板
    changeEditorPanel(target) {
      if (target === '') {
        this.curPanelModel = 'show-default-panel'
        return
      }
      let type = ''
      if (typeof target === 'object') {
        const isSvg = target.type === 'image' && target.flag === 'icon'
        const isWords = target.type === 'text'
        const isImg = target.type === 'image'
        if (isSvg) {
          type = 'show-icon-panel'
          const ref = this.$refs['icon-style-panel']
          ref.show(this.svgList)
          this.curPanelModel = 'show-icon-style-panel'
        } else if (isImg) {
          type = 'show-img-panel'
        } else if (isWords) {
          type = 'show-text-panel'
        }
      } else {
        type = target
      }

      const activeObj = this.editorCanvas.getActiveObj()
      if (!activeObj) return

      this.workspace.translateY = 0
      this.lastPanelModel = this.curPanelModel // 记录上次打开的面板
      this.curPanelModel = type
      switch (type) {
        case 'show-text-panel':
          {
            const ref = this.$refs['text-panel']
            ref.show(activeObj)
          }
          break
        case 'show-icon-panel':
          {
            const ref = this.$refs['icon-panel']
            ref.show(activeObj)
          }
          break
        case 'show-img-panel':
          {
            const ref = this.$refs['default-panel']
            const refs = this.$refs['img-panel']
            ref.show(activeObj)
            refs.show(activeObj)
          }
          break
        case 'show-textarea-panel':
          {
            const ref = this.$refs['textarea-modal']
            ref.show(activeObj.text)
          }
          break
        case 'show-text-colors-panel':
          {
            const ref = this.$refs['text-colors-panel']
            ref.show(this.colorList)
            // this.centerObject({ activeObj, height: 500 })
          }
          break
        case 'show-icon-style-panel':
          {
            const ref = this.$refs['icon-style-panel']
            ref.show(this.svgList, activeObj)
            // this.centerObject({ activeObj, height: 500 })
          }
          break
        case 'show-styles-panel':
          {
            const $ref = this.$refs['styles-panel']

            $ref.show(activeObj, this.editorCanvas.fabricInstance, this.fontFamilyList)
            // this.centerObject({ activeObj, height: 500 })
          }
          break
        case 'show-transparent-panel':
          {
            const ref = this.$refs['transparent-panel']
            const value = this.editorCanvas.getOpacity()
            ref.opacity = value * 1
            // this.centerObject({ activeObj })
          }

          break

        case 'show-luminance-panel':
          {
            const ref = this.$refs['luminance-panel']
            const value = this.editorCanvas.getBrightness()
            ref.brightness = value
          }

          break
        case 'show-layer-level-panel':
          {
            const ref = this.$refs['layer-level-panel']
            const levels = this.layers.length
            if (levels && levels > 1) {
              const max = levels
              ref.layerLevel.min = 1
              ref.layerLevel.max = max
              ref.value = activeObj.order + 1
              ref.lastValue = ref.value
              ref.initValue = ref.value
            } else {
              ref.layerLevel.min = 1
              ref.layerLevel.max = 1
              ref.value = 1
              ref.lastValue = 1
              ref.initValue = 1
            }
            // this.centerObject({ activeObj })
            ref.show(activeObj)
          }
          break
        case 'show-cut-panel':
          {
            const ref = this.$refs['img-cut-panel']
            const cutImg = {
              src: activeObj.src,
              width: activeObj.width * activeObj.scaleX,
              height: activeObj.height * activeObj.scaleY,
            }
            ref.show(activeObj, cutImg)
          }
          break
      }
    },

    // 双击编辑文字
    changeTextPanel(target) {
      this.lastPanelModel = this.curPanelModel // 记录上次打开的面板
      this.curPanelModel = 'show-textarea-panel'
      this.$refs['textarea-modal'].show(target.text)
    },

    // 点击外部区域
    handleOutside() {
      this.changeEditorPanel('show-default-panel')
      this.editorCanvas.fabricInstance.discardActiveObject()
      // this.editorCanvas.fabricInstance.requestRenderAll()
    },
    // 返回
    undo() {
      this.editorCanvas.undo()
      this.curPanelModel = 'show-default-panel'
      setTimeout(() => {
        this.getNewObjectList()
      }, 100)
    },

    // 前进
    redo() {
      this.editorCanvas.redo()
      this.curPanelModel = 'show-default-panel'
      setTimeout(() => {
        this.getNewObjectList()
      }, 100)
    },

    // 添加文字图层
    async addText() {
      try {
        await this.editorCanvas.createTxt()
        this.getNewObjectList()
      } catch (error) {
        console.error('添加文字出错', error)
      }
    },

    // 更新文字图层
    async canvasUpdateText(text) {
      await this.editorCanvas.createTxt(text)
      const activeObj = this.editorCanvas.getActiveObj()
      this.changeEditorPanel(activeObj)
    },

    // 更新字体颜色
    changeTextColorValue(value) {
      const activeObj = this.editorCanvas.getActiveObj()
      if (activeObj.type === 'text') {
        this.editorCanvas.changeTextConfig('fill', value)
      }
    },

    // 更新文字属性
    changeTextValue({ key, val }) {
      this.editorCanvas.changeTextConfig(key, val)
    },

    // 添加图片图层
    async addImage(url) {
      try {
        await this.editorCanvas.createImage(url)
        setTimeout(() => {
          this.getNewObjectList()
          this.changeEditorPanel(this.editorCanvas.getActiveObj())
        }, 50)
      } catch (error) {
        console.error('添加图片出错', error)
      }
    },

    // 替换图片图层
    replaceImg(image) {
      const activeObj = this.editorCanvas.getActiveObj()

      const scaleWZoom = this.modelWidth / image.width
      const scaleHZoom = this.modelHeight / image.height
      const maxScale = Math.max(scaleWZoom, scaleHZoom)

      activeObj.setSrc(
        image.src,
        () => {
          activeObj.src = image.src
          activeObj.set('width', image.width)
          activeObj.set('height', image.height)
          activeObj.set('maxScale', maxScale)
          activeObj.set('scaleX', maxScale / 2)
          activeObj.set('scaleY', maxScale / 2)

          activeObj.setCoords()
          this.editorCanvas.fabricInstance.requestRenderAll()
          this.editorCanvas.updateCanvasStack()
        },
        {
          crossOrigin: 'anonymous',
        }
      )
    },

    // 更新图片对齐位置
    async handleDirection(type) {
      const activeObj = this.editorCanvas.getActiveObj()
      await this.editorCanvas.setImageDirection(type, activeObj)
      const ref = this.$refs['img-panel']
      ref.show(activeObj)
    },

    // 修改left坐标
    handleLeft(val) {
      const activeObj = this.editorCanvas.getActiveObj()
      activeObj.set('left', val)
      activeObj.setCoords()
      this.editorCanvas.fabricInstance.requestRenderAll()
      this.editorCanvas.updateCanvasStack()
    },

    // 修改top坐标
    handleTop(val) {
      const activeObj = this.editorCanvas.getActiveObj()
      activeObj.set('top', val)
      activeObj.setCoords()
      this.editorCanvas.fabricInstance.requestRenderAll()
      this.editorCanvas.updateCanvasStack()
    },

    // 修改缩放比例
    handleScale(val) {
      const activeObj = this.editorCanvas.getActiveObj()
      activeObj.set('scaleX', val / 100)
      activeObj.set('scaleY', val / 100)
      this.editorCanvas.fabricInstance.requestRenderAll()
      this.editorCanvas.updateCanvasStack()
    },

    // 添加素材图层
    async changeIconValue(item) {
      await this.editorCanvas.createSvg(item)
      setTimeout(() => {
        this.getNewObjectList()
      }, 100)
    },

    // 替换素材图层
    async updateIconValue(image) {
      const activeObj = this.editorCanvas.getActiveObj()
      const scaleWZoom = this.modelWidth / image.width
      const scaleHZoom = this.modelHeight / image.height

      const maxScale = Math.max(scaleWZoom, scaleHZoom)
      // const src = await getUrlBase64(image.src)
      activeObj.setSrc(
        image.src,
        () => {
          activeObj.src = image.src
          activeObj.set('width', image.width)
          activeObj.set('height', image.height)
          activeObj.set('maxScale', maxScale)
          activeObj.set('scaleX', maxScale / 2)
          activeObj.set('scaleY', maxScale / 2)
          activeObj.setCoords()
          this.editorCanvas.fabricInstance.requestRenderAll()
          this.editorCanvas.updateCanvasStack()
        },
        {
          crossOrigin: 'anonymous',
        }
      )
    },

    // 获取最新图层数据
    async getNewObjectList() {
      const list = await this.editorCanvas.getObjects()
      this.layers = list.map((layer, index) => {
        layer.order = index
        return layer
      })
    },

    // 改变不透明值
    changeTransparentValue(value) {
      this.editorCanvas.setOpacity(value)
    },

    // 改变图片亮度值
    changeBrightnessValue(value) {
      this.editorCanvas.setBrightness(value)
    },

    // 更新图层层级
    changeLayerLevelValue(value) {
      this.editorCanvas.updateLevel(value)
    },

    // 复制图层
    copyLayer() {
      this.editorCanvas.copyLayer()
    },

    // 删除图层
    async deleteLayer() {
      await this.editorCanvas.delItem()
      this.getNewObjectList()
      this.resetEditorTools()
    },

    // 选择图层
    onSelectObj(item) {
      const objectList = this.editorCanvas.getObjects()
      const activeObj = objectList.find(el => {
        return item.id === el.id
      })
      if (activeObj) {
        activeObj.setControlsVisibility({
          bl: false,
          mb: false,
          ml: false,
          mt: false,
          tl: false,
          tr: false,
        })
        this.editorCanvas.setActiveSelect(activeObj)
        this.editorCanvas.fabricInstance.requestRenderAll()
        this.changeEditorPanel(activeObj)
      }
    },

    // 旋转图层
    rotateLayer(val) {
      const activeObj = this.editorCanvas.getActiveObj()
      activeObj.rotate(val)
      this.editorCanvas.fabricInstance.requestRenderAll()
      this.editorCanvas.updateCanvasStack()
    },

    // 翻转图层
    flipXLayer(value) {
      const activeObj = this.editorCanvas.getActiveObj()
      activeObj.set('flipX', value)
      this.editorCanvas.fabricInstance.requestRenderAll()
      this.editorCanvas.updateCanvasStack()
    },

    // 裁剪图层
    cropUpload(image) {
      const activeObj = this.editorCanvas.getActiveObj()

      activeObj.setSrc(image, () => {
        activeObj.set('dirty', true)
        activeObj.setCoords()
        this.editorCanvas.fabricInstance.requestRenderAll()
        this.editorCanvas.updateCanvasStack()
      })
    },
    // 把base64 转换成文件对象
    dataURLtoFile(base64, fileName) {
      const arr = base64.split(',')
      const [header, data] = arr
      const mime = header.match(/:(.*?);/)[1]
      const bstr = atob(data)
      const length = bstr.length
      const u8arr = new Uint8Array(length)

      for (let i = 0; i < length; i++) {
        u8arr[i] = bstr.charCodeAt(i)
      }

      return new File([u8arr], fileName, { type: mime })
    },
    /**
     * 从base64编码的图片中获取扩展名
     */
    getExtensionFromBase64(base64) {
      const re = new RegExp('data:image/(?<ext>.*?);base64,.*')
      const res = re.exec(base64)
      if (res) {
        return res.groups.ext
      }
    },
  },
}
