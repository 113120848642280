/*
 * @Description:
 * @Author: 王立俊
 * @Date: 2023-08-08 19:16:47
 * @LastEditTime: 2024-06-18 15:13:00
 * @LastEditors: Please set LastEditors
 * 去没人的岛 摸鲨鱼的角.
 */
import axios from 'axios'

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: 'https://bimei.td.lcqimeng.com/dev-api',
  // baseURL: 'https://admin.bi-media.cn/prod-api',
  // 超时
  timeout: 60000,
})
// request拦截器
service.interceptors.request.use(
  config => {
    // get请求映射params参数
    if (config.method === 'get' && config.params) {
      let url = config.url + '?'
      for (const propName of Object.keys(config.params)) {
        const value = config.params[propName]
        var part = encodeURIComponent(propName) + '='
        if (value !== null && typeof value !== 'undefined') {
          if (typeof value === 'object') {
            for (const key of Object.keys(value)) {
              const params = propName + '[' + key + ']'
              var subPart = encodeURIComponent(params) + '='
              url += subPart + encodeURIComponent(value[key]) + '&'
            }
          } else {
            url += part + encodeURIComponent(value) + '&'
          }
        }
      }
      url = url.slice(0, -1)
      config.params = {}
      config.url = url
    }

    return config
  },
  error => {
    Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  res => {
    // 未设置状态码则默认成功状态
    const code = res.data.code || 200
    if (code !== 200) {
      return Promise.reject('error')
    } else {
      return res.data
    }
  },
  error => {
    return Promise.reject(error)
  }
)

export default service
