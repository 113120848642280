<!--
 * @Description: 默认操作栏
 * @Author: 王立俊
 * @Date: 2023-06-14 11:46:36
 * @LastEditTime: 2024-06-18 15:10:20
 * @LastEditors: Please set LastEditors
 * 去没人的岛 摸鲨鱼的角.
-->
<template>
  <!-- 默认操作栏 -->
  <div v-show="curPanelModel !== 'show-styles-panel'" class="editor-panel-default">
    <van-overlay :show="showImage" @click="showImage = false">
      <div class="wrapper" @click.stop>
        <div class="content">
          <div><span>您需要保证设计中使用的图片版权所有权，本平台不对图片的使用承担侵权责任！</span></div>
          <div class="footer">
            <div class="radio">
              <input v-model="pick" type="radio" :value="true" @click="handleRadio" />
              <span class="desc">不再提醒</span>
            </div>
            <div>
              <div class="editor-panel-btn s-btn">
                <input ref="input" type="file" accept="image/*" class="select-file" @change="addImage" />
                <span class="confirm">知道了</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </van-overlay>
    <!-- 图片上传加载中 -->
    <van-overlay :show="showLoading" @click="showLoading = false">
      <div class="uploading-loading" @click.stop>
        <van-loading vertical color="#1989fa">{{ loadingTitle }}</van-loading>
      </div>
    </van-overlay>
    <div v-show="curPanelModel !== 'show-icon-style-panel' &&
    curPanelModel !== 'show-text-colors-panel' &&
    curPanelModel !== 'show-transparent-panel' &&
    curPanelModel !== 'show-luminance-panel' &&
    curPanelModel !== 'show-layer-level-panel' &&
    curPanelModel !== 'show-cut-panel'
    " class="tools-bar">
      <div class="layer-btns">
        <span class="editor-tool-btn">
          <i :class="['red', 'iconfont', 'icon-undo', { disabled: !unRedoStatus.undo }]" @click="undo" />
          <span :class="['default', { disabled: !unRedoStatus.undo }]">撤销</span>
        </span>
        <span class="editor-tool-btn">
          <i :class="['blue', 'iconfont', 'icon-redo', { disabled: !unRedoStatus.redo }]" @click="redo" />
          <span :class="['default', { disabled: !unRedoStatus.redo }]">恢复</span>
        </span>
      </div>
      <div v-show="curPanelModel !== 'show-default-panel' && curPanelModel !== 'show-transparent-panel'"
        class="layer-btns">
        <!-- || curPanelModel === 'show-icon-panel' -->
        <template v-if="curPanelModel === 'show-img-panel'">
          <span class="editor-tool-btn">
            <i class="iconfont icon-flip" @click="onFlip" />
            <span class="desc">翻转</span>
          </span>
          <span class="editor-tool-btn">
            <i class="iconfont icon-rotate" @click="onRotate" />
            <span class="desc">旋转</span>
          </span>
        </template>
        <span class="editor-tool-btn">
          <i class="iconfont icon-opacity" @click="showTransparentPanel" />
          <span>透明度</span>
        </span>
        <span class="editor-tool-btn">
          <i class="iconfont icon-layer" @click="showLayerLevelPanel" />
          <span>图层</span>
        </span>
        <span v-if="curPanelModel === 'show-img-panel'" class="editor-tool-btn">
          <i class="iconfont icon-luminance" @click="showLuminancePanel" />
          <span>亮度</span>
        </span>
        <span v-if="curPanelModel === 'show-text-panel'" class="editor-tool-btn">
          <i class="iconfont icon-copy" @click="copyLayer" />
          <span>复制</span>
        </span>
        <span class="editor-tool-btn">
          <i class="iconfont icon-delete" @click="deleteLayer" />
          <span>删除</span>
        </span>
      </div>
    </div>
    <div v-show="curPanelModel === 'show-default-panel' && curPanelModel !== 'show-transparent-panel'"
      class="editor-btn-group">
      <span class="editor-panel-btn s-btn s-text" @click="addText">添加文字</span>
      <span v-if="!pick" class="editor-panel-btn s-btn s-image" @click="showImage = true">
        <!-- <input ref="input" type="file" accept="image/*" class="select-file" @change="show = true" /> -->
        <span>上传图片</span>
      </span>
      <span v-if="pick" class="editor-panel-btn s-btn s-image">
        <input ref="input" type="file" accept="image/*" class="select-file" @change="addImage" />
        <span>上传图片</span>
      </span>
      <span class="editor-panel-btn s-btn s-material" @click="addMaterial">添加素材</span>
    </div>
    <div v-if="curPanelModel === 'show-default-panel'" class="default-name">
      <div class="name-box">
        <div v-for="(item, index) in layers" :key="index" class="name" @click="onSelectObj(item, index)">
          {{ item.name || '未定义' }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { insertImgFile } from '@/utils/util'
import { uploadFile, FileUrl } from '@/api/base'

export default {
  name: 'DefaultPanel',
  props: {
    unRedoStatus: {
      type: Object, // 类型
      required: true, // 是否必须
      default: () => {
        // 返回值
        return {}
      },
    },
    curPanelModel: {
      type: String, // 类型
      required: true, // 是否必须
      default: () => {
        // 返回值
        return ''
      },
    },
    layers: {
      type: Array,
      required: true,
      default: () => {
        return []
      },
    },
  },
  data () {
    return {
      showLoading: false,
      inputDom: '',
      result: null,
      loadingTitle: '上传中...',
      showImage: false,
      pick: null,
      lastIndex: null,
      activeName: null,
      activeObj: {},
    }
  },
  methods: {
    // 组件传值
    show (obj) {
      this.activeObj = obj
    },
    // 撤销
    undo () {
      if (!this.unRedoStatus.undo) return
      this.$emit('undo', 'undo')
    },
    // 重做
    redo () {
      if (!this.unRedoStatus.redo) return
      this.$emit('redo', 'redo')
    },
    // 翻转
    onFlip () {
      this.$emit('onFlip', this.activeObj.flipX === 0 ? 180 : 0)
    },
    // 旋转
    onRotate () {
      // 逆时针90°旋转
      const currAngle = this.activeObj.angle === -360 ? 0 : this.activeObj.angle // 当前图层的角度
      const angle = currAngle === -360 ? 90 : currAngle - 90
      this.$emit('rotateLayer', angle)
    },
    // 打开透明度面板
    showTransparentPanel () {
      this.$emit('showPanel', 'show-transparent-panel')
    },
    // 打开层级面板
    showLayerLevelPanel () {
      this.$emit('showPanel', 'show-layer-level-panel')
    },

    // 打开亮度面板
    showLuminancePanel () {
      this.$emit('showPanel', 'show-luminance-panel')
    },
    // 复制图层
    copyLayer () {
      this.$emit('copyLayer')
    },
    // 删除图层
    deleteLayer () {
      this.$emit('deleteLayer')
    },
    // 添加文字
    async addText (e) {
      await this.$emit('addText')
      this.$emit('showPanel', { type: 'text' })
    },

    // 不再提醒 单选操作
    handleRadio ({ target: { _value } }) {
      this.pick = !_value
    },

    // 添加图片 提示框按钮操作
    async addImage (e) {
      this.showImage = false
      this.inputDom = e.target
      const files = this.inputDom.files
      const file = files[0]
      if (!/image\/\w+/.test(file.type)) {
        return this.$toast('请上传正确的图片格式')
      }
      // 图片尺寸超过2mb
      if (file.size > 2 * 1024 * 1024) {
        this.photoCompress(
          file,
          {
            // 调用压缩图片方法
            quality: 0.2,
          },
          base64Codes => {
            const bl = this.base64UrlToBlob(base64Codes)
            this.loadingTitle = '压缩上传中...'
            this.uploadImg(bl) // 请求图片上传接口
          }
        )
      } else {
        this.loadingTitle = '上传中...'
        this.uploadImg(file)
      }
    },
    // 压缩图片
    photoCompress (file, obj, callback) {
      const that = this
      const ready = new FileReader()
      /* 开始读取指定File对象中的内容. 读取操作完成时,返回一个URL格式的字符串. */
      ready.readAsDataURL(file)
      ready.onload = function () {
        const re = this.result
        that.canvasDataURL(re, obj, callback) // 开始压缩
      }
    },

    /*利用canvas数据化图片进行压缩
     * 图片转base64
     */
    canvasDataURL (path, obj, callback) {
      const img = new Image()
      img.src = path
      img.crossOrigin = 'anonymous'

      img.onload = function () {
        const that = this // 指到img
        // 默认按比例压缩
        let w = that.width,
          h = that.height
        const scale = w / h
        w = obj.width || w
        h = obj.height || w / scale
        let quality = 0.2 // 默认图片质量为0.7
        // 生成canvas
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')
        // 创建属性节点
        const anw = document.createAttribute('width')
        anw.nodeValue = w
        const anh = document.createAttribute('height')
        anh.nodeValue = h
        canvas.setAttributeNode(anw)
        canvas.setAttributeNode(anh)
        ctx.drawImage(that, 0, 0, w, h)
        // 图像质量
        if (obj.quality && obj.quality >= 1 && obj.quality < 0) {
          quality = obj.quality
        }
        // quality值越小，所绘制出的图像越模糊
        const base64 = canvas.toDataURL('image/jpeg', quality)
        // 回调函数返回base64的值
        callback(base64)
      }
    },
    // 上传图片
    async uploadImg (file) {
      try {
        this.showLoading = true
        const formData = new FormData()
        formData.append('file', file)
        formData.append('uploadType', 0)
        // 上传
        const {
          data: { filePath },
        } = await uploadFile(formData)
        const url = FileUrl + filePath
        const _this = this
        const imgEl = await insertImgFile(url)
        this.inputDom.value = ''
        await _this.$emit('addImage', imgEl)
        this.showLoading = false
      } catch (error) {
        this.loadingTitle = '上传失败...'
        this.inputDom.value = ''
        setTimeout(() => {
          this.showLoading = false
        }, 800)
      }
    },

    /* base64 转 Blob 格式 和file格式*/
    base64UrlToBlob (urlData) {
      const arr = urlData.split(','),
        mime = arr[0].match(/:(.*?);/)[1], // 去掉url的头，并转化为byte
        bstr = atob(arr[1]) // 处理异常,将ascii码小于0的转换为大于0
      let n = bstr.length
      const u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      // 转blob
      // return new Blob([u8arr], {type: mime})
      const filename = Date.parse(new Date()) + '.jpg'
      // 转file
      return new File([u8arr], filename, { type: mime })
    },

    // 添加素材
    addMaterial () {
      this.$emit('showPanel', { type: 'image', flag: 'icon' })
    },
    // 选择层级
    onSelectObj (item, index) {
      this.activeName = index
      if (this.lastIndex === index) {
        this.activeName = null
        this.lastIndex = null
        return
      }
      this.lastIndex = index
      this.$emit('onSelectObj', item)
    },
  },
}
</script>
<style lang="scss" scoped>
.wrapper {
  height: 20%;

  .content {
    margin: 15px;
    padding: 15px;
    flex-shrink: 0;
    border-radius: 10px;
    background: #fff;
    color: #333942;
    font-size: 12px;

    .select-file {
      width: 50px;
      z-index: 1;
      opacity: 0;
      position: absolute;
      cursor: pointer;
    }

    .footer {
      padding-top: 25px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .radio {
        display: flex;
        align-items: stretch;

        .desc {
          color: #9ba2ac;
          font-size: 12px;
        }
      }

      .confirm {
        padding: 4px 6px;
        flex-shrink: 0;
        border-radius: 5px;
        background: #4391fc;
        color: #fff;
        font-size: 12px;
      }
    }
  }
}

.default {
  color: #333942;
}

.red {
  color: #ff487d;
}

.blue {
  color: #4391fc;
}

.disabled {
  color: #33394266;
}

i {
  font-size: 15px;
  margin: 4px;
  color: #333942;
}

.editor-panel-default {

  // 属性设置
  .tools-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .layer-btns {
      display: flex;
      align-items: center;
      padding: 10px;

      .editor-tool-btn {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 4px;

        span {
          font-size: 12px;
        }
      }
    }
  }

  // 操作栏
  .editor-btn-group {
    display: flex;
    justify-content: space-between;
    text-align: center;
    background-color: #ffffff;
    box-shadow: 0px -6px 16px 0px rgba(51, 57, 66, 0.06);
    padding: 6px 15px;
    color: #fff;
    text-align: center;
    font-size: 12px;

    .editor-panel-btn {
      position: relative;
      display: flex;
      flex-direction: column;
      position: relative;

      .select-file {
        width: 70px;
        z-index: 1;
        opacity: 0;
        position: absolute;
        cursor: pointer;
      }
    }

    .s-btn {
      display: block;
      padding: 8px 22px;
      border-radius: 5px;
    }

    .s-text {
      background: #00ce75;
    }

    .s-image {
      background: #4391fc;
    }

    .s-material {
      background: #8883ff;
    }
  }

  // 层级关系
  .default-name {
    position: relative;
    padding: 9px 15px;
    background-color: #ffffff;
    border-bottom: 1px solid #edeff3;
    display: flex;
    /* 将子元素横向排列 */
    align-items: center;
    overflow: hidden;

    .name-box {
      position: relative;
      display: flex;
      /* 将子元素横向排列 */
      white-space: nowrap;
      /* 避免子元素在父元素内换行 */
      overflow-x: scroll;

      ::-webkit-scrollbar {
        width: 0 !important;
      }

      ::-webkit-scrollbar {
        width: 0 !important;
        height: 0;
      }

      .name {
        margin-right: 6px;
        padding: 4px 6px;
        border-radius: 5px;
        border: 1px solid #cdd5e1;
        background: #fff;
        color: #9ba2ac;
        text-align: center;
        font-size: 12px;
      }

      .name:active {
        color: #4391fc;
        border: 1px solid #4391fc;
      }
    }
  }
}
</style>
